<template>
  <div class="p-10 pr-16 all">
    <div v-if="position === 1">
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Contrôle de caisse
          </div>
        </div>

        <div class="w-1/5">
          <bouton  label="Effectuer un contrôle" height="54.4px" @info="continuer"/>
        </div>
      </div>

      <div class="mt-10 pl-4">
        <global />
      </div>
    </div>

    <div v-if="position === 2">
      <detailControle @oga="goBack"/>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import global from '../../component/cdevs/controleCaisse/globalData'
import detailControle from '../../component/cdevs/controleCaisse/details'

export default {
  name: "Index",

  components: {
    bouton,
    global,
    detailControle
  },

  data () {
    return {
      icons: {
        back,
      },
      position: 1,
    }
  },

  created () {
  },

  methods: {
    goBack () {
      this.position = 1
    },

    continuer () {
      this.position = 2
    },

    retour () {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
