<template>
  <div class="bg-white rounded-10 p-10 text-left flex">
    <div class="w-1/2 pr-10 mr-10">
      <div class="text-c16 font-c6">Billetage</div>

      <div class="mt-10">
        <billetage @info="retourBillet"/>
      </div>
    </div>

    <div class="w-1/2 pl-20 bloc2">

      <div class="mt-10">
        <div class="text-c12">
          <span class="text-98">Solde thérotique</span> : {{ soldeTheo.toLocaleString() }} F
        </div>

        <div class="text-c12 mt-3">
          <span class="text-98">Solde réel</span> : {{ soldeReel.toLocaleString() }} F
        </div>

        <div class="text-c12 mt-3">
          <span class="text-98">Differentiel</span> <span class="text-red">: {{ (soldeTheo - soldeReel).toLocaleString() }} F</span>
        </div>
      </div>

      <div class="mt-14">
        <div class="text-c16 text-98">
          Montant contrôlé
        </div>

        <div class="text-c24 font-c6 mt-2">
          {{ soldeReel.toLocaleString() }} <span class="text-90">FCFA</span>
        </div>
      </div>

      <textare placeholder="Ecrivez une note..." class="mt-16" radius="5px" height="163px" @info="retourNote"/>
    </div>
  </div>
</template>

<script>
import billetage from "../../helper/add/billetage"
import textare from '../../helper/form/textarea'

export default {
  name: "Index",
  components: {
    billetage,
    textare
  },
  props: {
  },

  data () {
    return {
      soldeReel: 0,
      soldeTheo: 0,
      info: null,
      note: '',
      billet: []
    }
  },

  created () {
    this.info = this.$store.getters.trackTemporal
    if (this.info){
      console.log(this.info)
      this.soldeTheo = this.info.stats.cashAMount
    }
  },

  methods: {
    retourBillet (answer) {
      if (answer.length > 0) {
        let total = 0
        for (let item in answer) {
          total = total + (answer[item].count * answer[item].amount)
        }
        this.billet = answer
        this.soldeReel = total
      }
      this.senderData()
    },

    retourNote (answer) {
      this.note = answer
      this.senderData()
    },

    senderData () {
      let obj = {
        meta: {
          reelAmount: this.soldeReel,
          theoriqueAmount: this.soldeTheo,
          diferentiel: this.soldeTheo - this.soldeReel,
          note: this.note
        },
        data: this.billet
      }

      this.$emit('info', obj)
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
</style>
