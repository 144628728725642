<template>
  <div class="bg-white rounded-10 p-10 text-left flex">
    <div class="w-1/2 mr-10">
      <div class="text-c16 font-c5 flex">
        <div class="w-1/3">Numero</div>
        <div class="w-1/3 ml-6 text-90">Solde théorique </div>
        <div class="w-1/3 ml-10 text-90">Solde réel</div>
      </div>

      <div
          v-if="charge && allData.length === 0"
          class="flex justify-center mt-16"
      >
        <easy-spinner
            type="dots"
            size="40"
        />
      </div>

      <div class="mt-10 flex" v-for="(item, index) in allData" :key="index">
        <templateMomo :index="index" :donne="item" @info="retourInfo"/>
      </div>
    </div>

    <div class="w-1/2 pl-20 bloc2">
<!--      <div class="flex items-center justify-end">-->
<!--        <radio height="22px" width="22px"/>-->
<!--        <div class="text-c16 text-60 ml-3">Ignorer cette étape</div>-->
<!--      </div>-->

      <div class="mt-10">
        <div class="text-c12">
          <span class="text-98">Solde thérotique</span> : {{ soldeTheo.toLocaleString() }} F
        </div>

        <div class="text-c12 mt-3">
          <span class="text-98">Solde réel</span> : {{ soldeReel.toLocaleString() }} F
        </div>

        <div class="text-c12 mt-3">
          <span class="text-98">Differentiel</span> <span class="text-red">: {{ (soldeTheo - soldeReel).toLocaleString() }} F</span>
        </div>
      </div>

      <div class="mt-14">
        <div class="text-c16 text-98">
          Montant contrôlé
        </div>

        <div class="text-c24 font-c6 mt-2">
          {{ soldeReel.toLocaleString() }} <span class="text-90">FCFA</span>
        </div>
      </div>

      <textare placeholder="Ecrivez une note..." @info="retourNote" class="mt-16" radius="5px" height="163px"/>
    </div>
  </div>
</template>

<script>
// import radio from '../../helper/form/radio'
import textare from '../../helper/form/textarea'
import templateMomo from './templateMomo'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    // radio,
    textare,
    templateMomo
  },
  props: {
  },

  data () {
    return {
      charge: true,
      allData: [],
      soldeReel: 0,
      soldeTheo: 0,
      note: '',
      dataTable: []
    }
  },

  created () {
    this.info = this.$store.getters.trackTemporal
    this.soldeTheo = this.info.stats.mobileMoneyAmount
    this.getAllMomo()
  },

  methods: {
    retourInfo (answer) {
      this.allData[answer.index].infoData = answer
      let reel = 0
      let tab = []
      for (let item in this.allData) {
        if (this.allData[item].infoData) {
          if (this.allData[item].infoData.activate) {
            reel = reel + this.allData[item].infoData.value
            tab.push(this.allData[item].id)
          }
        }
      }

      this.soldeReel = reel
      this.dataTable = tab

      this.senderData()
    },

    retourNote (answer) {
      this.note = answer
      this.senderData()
    },

    senderData () {
      let obj = {
        meta: {
          reelAmount: this.soldeReel,
          theoriqueAmount: this.soldeTheo,
          diferentiel: this.soldeTheo - this.soldeReel,
          note: this.note
        },
        data: this.dataTable
      }

      this.$emit('info', obj)
    },

    getAllMomo() {
      http.post(apiroutes.baseURL + apiroutes.allControlTrans,{
        id: this.$route.query.y,
        type: 'MOBILE_MONEY'
      })
          .then(response => {
            console.log('momo')
            console.log(response)
            this.allData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
