<template>
  <div class=" all">
    <success v-if="activeSuccess" :message="message" :pdf="pdf" :activation="activeSuccess"  @oga="retour"/>
    <recap :activation="activeRecap" :donne="finalData" v-if="activeRecap" @oga="retourRecap"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Contrôle de caisse
          </div>
        </div>

        <div class="w-1/5">
          <bouton v-if="position < 3" label="Etape suivante" height="54.4px" @info="continuer"/>
          <bouton v-if="position === 3" label="Enregistrer" height="54.4px" @info="continuer" />
        </div>
      </div>

      <div class="mt-10 pl-4">
        <div class="flex mt-6 justify-center bg-white rounded-10 p-6 pl-10 pr-0">
          <div class="w-1/2" @click="selected(1)">
            <steep-line :all-steep="allSteep" :position="position" :index="0"/>
          </div>
          <div class="w-1/2" @click="selected(2)">
            <steep-line :all-steep="allSteep" :position="position" :index="1"/>
          </div>
          <div class="w-1/5" @click="selected(3)">
            <steep-line :all-steep="allSteep" :position="position" :index="2"/>
          </div>
        </div>
      </div>

      <div class="mt-10 pl-4">
        <espece v-if="position === 1" @info="retourEspece"/>
        <cheque v-if="position === 2" @info="retourCheque"/>
        <mobile v-if="position === 3" @info="retourMomo"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import bouton from '../../helper/add/button'
import steepLine from '../../helper/add/steepLine'
import espece from '../../cdevs/controleCaisse/espece'
import cheque from '../../cdevs/controleCaisse/cheque'
import mobile from '../../cdevs/controleCaisse/mobileMoney'
import recap from '../../popup/recapControle'
import success from '../../popup/success'

export default {
  name: "Index",

  components: {
    bouton,
    steepLine,
    espece,
    cheque,
    mobile,
    recap,
    success
  },

  data () {
    return {
      icons: {
        back,
      },
      allSteep: ['Espèce', 'Chèque', 'MOBILE MONEY'],
      position: 1,
      activeRecap: false,
      activeSuccess: false,
      message: 'Contrôle de caisse enregistré avec succès',
      finalData: {},
      pdf: null
    }
  },

  created () {
  },

  methods: {
    selected (index) {
      this.position = index
    },

    retourEspece (answer) {
      this.finalData.espece = answer
    },

    retourCheque (answer) {
      this.finalData.cheque = answer
    },

    retourMomo (answer) {
      this.finalData.momo = answer
    },

    continuer () {
      if (this.position < 3) {
        this.position = this.position + 1
      } else {
        this.activeRecap = true
      }
    },

    retourRecap (answer) {
      if (answer) {
        this.pdf = answer
        this.activeSuccess = true
      }
      this.activeRecap = false
    },

    retour () {
      this.activeSuccess = false
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
