<template>
  <div class="w-full text-c13">
    <div class="flex items-center w-full">
      <div class="w-1/3 flex items-center">
        <radio class="w-1/5" height="16px" @info="retourActive"/>
        <div class="ml-2 w-4/5">
          <span v-if="donne.meta">
            {{ donne.meta.reference }}
          </span>
          <span v-if="!donne.meta">-</span>
        </div>
      </div>

      <div class="w-1/3 ml-6 text-90 inputo flex items-center pl-2">
        <div>{{ donne.amount.toLocaleString() }}</div>
      </div>

      <div class="w-1/3 ml-10 text-90">
        <inputo type="number" @info="retourValue"/>
      </div>
    </div>
  </div>
</template>

<script>
import radio from '../../helper/form/radio'
import inputo from '../../helper/form/input'

export default {
  name: "Index",
  components: {
    radio,
    inputo
  },
  props: {
    donne: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      value: null,
      activator: false,
      temoin: null
    }
  },

  methods: {
    retourValue(answer) {
      if (answer !== null && answer !== '') {
        if (answer !== this.temoin){
          this.temoin = answer
          this.value = answer
          this.sender()
        }
      }
    },

    retourActive (answer) {
      this.activator = answer
    },

    sender () {
      let obj = {
        activate: this.activator,
        index: this.index,
        value: parseInt(this.value)
      }
      this.$emit('info', obj)

    }
  },
}
</script>

<style lang="scss" scoped>
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
