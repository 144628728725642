<template>
  <div class="w-full">
        <steepBilletage :montant="10000" @info="retour10"/>
        <steepBilletage :montant="5000" class="mt-4" @info="retour5"/>
        <steepBilletage :montant="2000" class="mt-4" @info="retour2"/>
        <steepBilletage :montant="1000" class="mt-4" @info="retour1"/>
        <steepBilletage :montant="500" class="mt-4" @info="retour05"/>
        <steepBilletage :montant="200" class="mt-4" @info="retour02"/>
        <steepBilletage :montant="100" class="mt-4" @info="retour01"/>
        <steepBilletage :montant="50" class="mt-4" @info="retour005"/>
        <steepBilletage :montant="25" class="mt-4" @info="retour002"/>
        <steepBilletage :montant="10" class="mt-4" @info="retour001"/>
        <steepBilletage :montant="5" class="mt-4" @info="retour0005"/>
    {{ totalAmount }}
  </div>
</template>

<script>
import steepBilletage from "../../cdevs/caisseDetail/steepBilletage"

export default {
  name: "Index",
  components: {
    steepBilletage,
  },
  props: {
  },

  data () {
    return {
      allBillet: [],
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    totalAmount: function () {
      if (this.allBillet.length > 0) {
        this.$emit('info', this.allBillet)
        return ''
      } else {
        return ''
      }
    }
  },

  methods: {
    retour10 (answer) {
      this.allBillet[0] = answer
    },

    retour5 (answer) {
      this.allBillet[1] = answer
    },

    retour2 (answer) {
      this.allBillet[2] = answer
    },

    retour1 (answer) {
      this.allBillet[3] = answer
    },

    retour05 (answer) {
      this.allBillet[4] = answer
    },

    retour02 (answer) {
      this.allBillet[5] = answer
    },

    retour01 (answer) {
      this.allBillet[6] = answer
    },

    retour005 (answer) {
      this.allBillet[7] = answer
    },

    retour002 (answer) {
      this.allBillet[8] = answer
    },

    retour001 (answer) {
      this.allBillet[9] = answer
    },

    retour0005 (answer) {
      this.allBillet[10] = answer
    },

    sender () {
      this.$emit('info', this.allBillet)
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
</style>
