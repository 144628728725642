<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c22 w-4/5"
            >
              Récapitulatif du contrôle de caisse
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="text-c24 mt-4 text-left flex bloc pb-6">
              <div class="w-1/2 text-90">
                Espèce
              </div>

              <div class="text-right w-1/2">
                {{ espece.toLocaleString() }} fcfa
              </div>
            </div>

            <div class="text-c24 mt-5 text-left flex bloc pb-6">
              <div class="w-1/2 text-90">
                Chèques
              </div>

              <div class="text-right w-1/2">
                {{ cheque.toLocaleString() }} fcfa
              </div>
            </div>

            <div class="text-c24 mt-5 text-left flex bloc pb-6">
              <div class="w-1/2 text-90">
                Mobile money
              </div>

              <div class="text-right w-1/2">
                {{ momo.toLocaleString() }} fcfa
              </div>
            </div>

            <div class="text-c24 mt-6 text-left flex pb-6">
              <div class="w-1/2 font-c6">
                <div>Total de caisse</div>
                <div class="text-red text-c18 font-c4">Solde précédent</div>
              </div>

              <div class="text-right w-1/2">
                <div class="font-c6">{{ (espece + cheque + momo).toLocaleString() }} fcfa</div>
                <div class="text-red text-c18 font-c4">
                  {{ (info.stats.cashAMount + info.stats.checkAmount + info.stats.mobileMoneyAmount).toLocaleString() }} fcfa
                </div>
              </div>
            </div>

            <div class="flex mt-10">
              <div class="w-full flex">
                <buton
                    class="w-1/2"
                    label="Annuler"
                    radius="5px"
                    height="54px"
                    background="#DDDDDD"
                    color="black"
                    :charge="charge"
                    @oga="fermer"
                />
                <buton
                    class="w-1/2 ml-6"
                    label="Enregistrer"
                    radius="5px"
                    height="54px"
                    :charge="charge"
                    @oga="continuer"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import document from '../../assets/icons/document.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    estimation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        arrow,
        transfere,
        document
      },
      error: null,
      charge: false,
      espece: 0,
      momo: 0,
      cheque: 0,
      info: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.info = this.$store.getters.trackTemporal
    console.log ('donne')
    console.log (this.donne)
    if (this.donne.espece) {
      this.espece = this.donne.espece.meta.reelAmount
    }

    if (this.donne.cheque) {
      this.cheque = this.donne.cheque.meta.reelAmount
    }

    if (this.donne.momo) {
      this.momo = this.donne.momo.meta.reelAmount
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    async continuer() {
      if (this.espece > 0 || this.momo > 0 || this.cheque > 0) {
        if (this.espece > 0) {
          await this.especeControle()
        }
        if (this.cheque > 0) {
          await this.chequeControle()
        }
        if (this.momo > 0) {
          await this.momoControle()
        }
      } else {
        this.error = "Vous n'avez entrer aucune valeur pour ce contrôle"
      }
    },

    async especeControle() {
      const obj = {
        wallet: this.info.id,
        data: this.donne.espece.data,
        meta: this.donne.espece.meta,
        type: "CASH"
      }

      // console.log(obj)
      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.doControle, obj)
          .then(response => {
            this.charge = false
            console.log('success')
            console.log(response)
            this.$emit('oga', response.pdf)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    async chequeControle() {
      const obj = {
        wallet: this.info.id,
        data: this.donne.cheque.data,
        meta: this.donne.cheque.meta,
        type: "BANK_CHECK"
      }

      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.doControle, obj)
          .then(response => {
            this.charge = false
            console.log('success')
            console.log(response)
            this.$emit('oga', response.pdf)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    async momoControle() {
      const obj = {
        wallet: this.info.id,
        data: this.donne.momo.data,
        meta: this.donne.momo.meta,
        type: "MOBILE_MONEY"
      }

      this.charge = true
      await http.post(apiroutes.baseURL + apiroutes.doControle, obj)
          .then(response => {
            this.charge = false
            console.log('success momo')
            console.log(response)
            this.$emit('oga', response.pdf)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .moyens{
      background-color: #F5F5F5;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .bloc{
      border-bottom: 1px solid #DDDDDD;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
