<template>
  <transfere :activation="activeTransfere" @oga="retourTransfere" v-if="activeTransfere"/>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">

    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c14 font-c5 text-left mb-6 pr-6">
          <div class="w-1/6">
            Date
          </div>
          <div class="w-1/6 ml-4">
            Type
          </div>
          <div class="w-1/6 ml-4">
            Auteur
          </div>
          <div class="w-1/6 ml-4">
            Solde théorique
          </div>
          <div class="w-1/6 ml-4 text-left">
            Solde réel
          </div>
          <div class="w-1/6 ml-4">
            Différentiel
          </div>
          <div class="w-1/6 ml-4">
            Caisse
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge && statData.length === 0"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div class="mt-10 text-60 mb-20" v-if="statData.length === 0">
          Aucun mouvement pour enregistrer pour le moment
        </div>

        <div
          v-for="(item, index) in statData"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="prev"
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import transfere from '../../popup/transfere'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'

export default {
  name: "Index",
  components: {
    liste,
    transfere
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        next,
        prev
      },
      statData: [],
      charge: true,
      activeTransfere: false,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
    }
  },

  created () {
    this.getInfo()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.getInfo()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.getInfo()
      }
    },

    getInfo() {
      this.charge = true
      const query = apiroutes.baseURL + apiroutes.allControlePaginate + '?id=' + this.$route.query.y +
          '&page=' + this.page + '&limit=' + this.limite

      http.get(query)
          .then(response => {
            console.log('all controle data')
            console.log(response)
            this.charge = false

            this.statData = response.data
            this.total = response.total
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourTransfere (answer) {
      if (answer) {
        this.activeBillet = true
      }
      this.activeTransfere = false
    },

    selected (item) {
      console.log(item)
      if (item.pdf) {
        window.open(item.pdf, '_blank');
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
